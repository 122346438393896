import { Col, Row, Spin } from "antd";
import { lazy, Suspense } from "react";
import Alerts from "./Alerts";
import FormTabs from "./FormTabs";

const renderLoader = () => <Spin className='cmt-spin' />;
const CarouselBanner = lazy(() => import('./CarouselBanner'));

const BannerForm = ({ t, settings, countryCode }) => {
  const handleRenderComponents = (component) => {
    if (typeof window !== 'undefined') {
      return (
        <Suspense fallback={renderLoader()}>
          {component}
        </Suspense>
      );
    }

    return null;
  };


  return (
    <Row justify='center' className='banner-form-container'>
      <Col lg={24} xs={24}>
        <div className="carousel-wrapper">
          {
            handleRenderComponents(
              <CarouselBanner t={t} settings={settings} countryCode={countryCode} />
            )
          }
        </div>

        <Alerts countryCode={countryCode} />
        <FormTabs t={t} settings={settings} />
      </Col>
    </Row>
  );
};

export default BannerForm;