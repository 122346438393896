import { useState } from 'react';
import { Row, Col, Modal, Button, Form, Input, message } from 'antd';
import { PhoneInput } from 'cmt-ui-kit';
import { sendReservationContact } from '../../../services/sendForm';

const CallCenterModal = ({ t, showCallCenterModal, handleShowCallCenterModal }) => {
  const [form] = Form.useForm();
  const countryCode = process.env.GATSBY_COUNTRY_VALUE;
  const [loading, setLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await sendReservationContact({
        'nameAndLastName': values.nameAndLastName,
        'phone': values.phone,
        'email': values.email,
      });

      setIsSuccess(true);
    } catch (err) {
      message.error({
        content: 'Error en el envío del formulario',
        style: {
          marginTop: '40vh',
        },
      });
      console.error('Error en el envío del formulario', err);
    } finally {
      setLoading(false);
    }
  };


  return (
    <Modal
      open={showCallCenterModal}
      onCancel={handleShowCallCenterModal}
      footer={false}
      closable={false}
      centered
      width={350}
      className="modal-form-wrapper"
    >
      <Row justify="center">
        <div className="modal-border-top" />
        {
          !isSuccess &&
            <Col lg={23} md={22} sm={22} xs={20}>
              <Row justify="center">
                <Col lg={20} sm={22} xs={24} className="mt-20">
                  <h3>{t('scheduleTime.selectBranchOffice.maxReservationTitle')}</h3>
                </Col>
                <Col lg={20} sm={22} xs={24}>
                  <p>{t('scheduleTime.selectBranchOffice.contactText')}</p>
                </Col>
                <Col lg={20} sm={22} xs={24} className="mb-30">
                  <Form
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                  >
                    <Form.Item
                      name="nameAndLastName"
                      className="mb-15"
                      rules={[{ required: true, message: 'Debes ingresar tu nombre y apellido' }]}
                    >
                      <Input
                        placeholder="Nombre y apellido"
                        size="large"
                        className='cmt-input'
                      />
                    </Form.Item>

                    <PhoneInput.ReactPhone
                      countryCode={countryCode}
                      form={form}
                    />

                    <Form.Item name="email" className="mb-20" rules={[{ required: true, message: 'Debes ingresar tu email' }]}>
                      <Input placeholder="Correo electrónico" size="large" className='cmt-input' />
                    </Form.Item>
                    <Row justify="center">
                      <Col lg={11} xs={24}>
                        <Button loading={loading} htmlType="submit" size="large" block>Enviar</Button>
                      </Col>
                    </Row>
                  </Form>
                </Col>
              </Row>
            </Col>
          }
          {
            isSuccess &&
              <Col lg={23} md={21} sm={21} xs={20}>
                <Row justify="center">
                  <Col lg={20} xs={24} className="mt-30">
                    <h3>{t('scheduleTime.selectBranchOffice.sentMessageTitle')}</h3>
                  </Col>
                  <Col lg={20} xs={24}>
                    <p dangerouslySetInnerHTML={{ __html: t('scheduleTime.selectBranchOffice.contactDisclaimerText') }} />
                  </Col>
                  <Col lg={9} xs={24} className="mt-20 mb-30">
                    <Button onClick={handleShowCallCenterModal} size="large" block>Cerrar</Button>
                  </Col>
                </Row>
              </Col>
          }
      </Row>
    </Modal>
  );
};

export default CallCenterModal;