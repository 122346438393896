import { useEffect, useState, useCallback } from "react"
import { Row, Col, Alert, Carousel } from 'antd'

const Alerts = ({ countryCode }) => {
  const wordpressUrl = `${process.env.GATSBY_WORDPRESS_GRAPHQL}`
  const [alertItems, setAlertItems] = useState();

  const fetchAlerts = useCallback(() => {
    const queryAlert = `{
      alerts(where: { status: PUBLISH, country: "${countryCode}" }) {
        nodes {
          title
          field {
            subtitle
            country
            content
            iconname
          }
        }
      }
    }`

    fetch(wordpressUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        query: queryAlert,
      })
    })
    .then(data => data.json())
    .then(response => setAlertItems(response))
    .catch(error => console.error('Error fetch Alerts: ', error))
  }, [])

  useEffect(() => {
    if (countryCode && wordpressUrl) {
      fetchAlerts()
    }
  }, [fetchAlerts])


  return (
    <>
    {
      (alertItems && alertItems.data && alertItems.data.alerts.nodes.length > 0) && (
        <Alert
          className="home-alert"
          closable
          message={
            <Row justify="center">
              <Col lg={22} md={24} xs={24}>
                <Row justify="center">
                  <Col lg={24} xs={24}>
                    <Carousel autoplay>
                    {
                      alertItems.data.alerts.nodes.map((alert, index) => {
                        return (
                          <div
                            className="alert-data-container mt-xs-0 m-xs-0"
                            key={index}
                          >
                            <Row
                              justify="end"
                              align="middle"
                              className="alert-content"
                            >
                              <Col lg={2} md={2} sm={2} xs={3}>
                                <Row justify="center">
                                  <Col>
                                    <span
                                      className={alert.field.iconname}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                              <Col
                                lg={21}
                                xs={21}
                                className="pl-xs-10"
                              >
                                <Row align="middle" gutter={5}>
                                  <Col>
                                    <h2>{alert.title}</h2>
                                  </Col>
                                  <Col
                                    className="alert-title-subtitle"
                                    style={{ display: "flex" }}
                                  >
                                    <div
                                      className="ml-lg-5"
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                      dangerouslySetInnerHTML={{
                                        __html: alert.field.subtitle,
                                      }}
                                    />
                                  </Col>
                                  <Col
                                    lg={24}
                                    className="alert-title-content"
                                    dangerouslySetInnerHTML={{
                                      __html: alert.field.content,
                                    }}
                                  />
                                </Row>
                              </Col>
                            </Row>
                          </div>
                        )
                      })
                    }
                    </Carousel>
                  </Col>
                </Row>
              </Col>
            </Row>
          }
        />
      )
    }
    </>
  )
}

export default Alerts